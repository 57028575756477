import axios from "axios";

import { Toast } from "../components/Alert/Alert";
import { message } from '../util/message'
import * as notasActions from "../store/modules/notas/actions";
import api from "../util/api";

export function get(pesquisa) {
    try {
        let url
        if (pesquisa.chaveNota !== '') {
            url = api.urlNota + `NotaFiscal/notas-tela?Filial=${pesquisa.filial === '' ? null : pesquisa.filial}&DataInicial=${pesquisa.dataInicial === '' ? null : pesquisa.dataInicial}&DataFinal=${pesquisa.dataFinal === '' ? null : pesquisa.dataFinal}&TipoNota=${pesquisa.tipoNota === '' ? null : pesquisa.tipoNota}&StatusNota=${pesquisa.statusNota === '' ? null : pesquisa.statusNota}&NumeroNota=${pesquisa.numeroNota === '' ? null : pesquisa.numeroNota}&Chave=${pesquisa.chaveNota === '' ? null : pesquisa.chaveNota}`
        } else {
            url = api.urlNota + `NotaFiscal/notas-tela?Filial=${pesquisa.filial === '' ? null : pesquisa.filial}&DataInicial=${pesquisa.dataInicial === '' ? null : pesquisa.dataInicial}&DataFinal=${pesquisa.dataFinal === '' ? null : pesquisa.dataFinal}&TipoNota=${pesquisa.tipoNota === '' ? null : pesquisa.tipoNota}&StatusNota=${pesquisa.statusNota === '' ? null : pesquisa.statusNota}&NumeroNota=${pesquisa.numeroNota === '' ? null : pesquisa.numeroNota}`
        }
        axios.get(url)
            .then(response => {

                let data = response.data.map(data => {
                    return { ...data, checked: false }
                });

                notasActions.getItems(data)

                Toast('success', message.pesquisa, 'top', 5000, true)

            }).catch((error) => {
                if (error.response) {
                    if (error.response.status !== 404) {
                        Toast('error', message.errorServidor, 'top', 5000, true)

                    } else {
                        Toast('warning', error.response.data.mensagem, 'top', 5000, true)

                    }
                } else {
                    Toast('error', `${message.errorServidor} \n ${api.urlNota}`, 'top', 5000, true)
                }
            })

    } catch (error) {
        Toast('error', `${message.errorServidor} \n ${api.urlNota}`, 'top', 5000, true)
        return
    }
}

export function put(update, tipoNota, params) {

    let objUpdate = {
        codigoFilial: 0,
        codigoTipoNota: update.tipoNota,
        numeroNota: update.numeroNota,
        serie: update.serieNota,
        dataNota: "",
        codigoRemetente: 0
    }

    objUpdate.codigoFilial = Number(update.filialNota.split("-", 1))
    objUpdate.codigoRemetente = Number(update.clienteNota.split("/", 1))
    objUpdate.dataNota = update.dataNota.split('/').reverse().join('-')

    switch (tipoNota) {
        case "E":
            try {
                return axios.put(api.urlNota + `NotaFiscal/entrada/${params}`, {
                    codigoFilial: objUpdate.codigoFilial,
                    codigoTipoNota: objUpdate.codigoTipoNota,
                    numeroNota: objUpdate.numeroNota,
                    serie: objUpdate.serie,
                    dataNota: objUpdate.dataNota,
                    codigoRemetente: objUpdate.codigoRemetente
                }).then((response) => {

                    return { status: true, data: response.data }

                }).catch(() => {
                    Toast('error', message.error, 'top', 5000, true)
                    return { status: false, statusCode: 500 }
                })

            } catch (error) {
                Toast('error', message.errorServidor, 'top', 5000, true)
                return { status: false, statusCode: 500 }

            }
        case "S":
            try {
                return axios.put(api.urlNota + `NotaFiscal/saida/${params}`, {
                    codigoFilial: objUpdate.codigoFilial,
                    codigoTipoNota: objUpdate.codigoTipoNota,
                    numeroNota: objUpdate.numeroNota,
                    serie: objUpdate.serie,
                    dataNota: objUpdate.dataNota
                }).then(
                    (response) => {

                        return { status: true, statusCode: response.status }
                    }

                ).catch(() => {

                    Toast('error', `${message.errorServidor} \n ${api.urlNota}`, 'top', 5000, true)
                    return { status: false, statusCode: 500 }
                })

            } catch (error) {
                Toast('error', message.error, 'top', 5000, true)
                return { status: false, statusCode: 500 }

            }
        default:
            return null

    }


}