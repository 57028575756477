import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function item(value) {
    dispatch({
        type: TYPES.NOTA,
        value
    });
}

export function copy(value) {
    dispatch({
        type: TYPES.NOTA_COPY,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_NOTA,
        field
    });
}

export function getItems(value) {
    dispatch({
        type: TYPES.NOTAS,
        value
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_NOTA,
        field
    });
}

export function clean() {
    dispatch({
        type: TYPES.CLEAN
    })
}

export function pesquisa(value, field) {
    dispatch({
        type: TYPES.PESQUISA,
        field,
        value
    })
}

export function update(value) {
    dispatch({
        type: TYPES.UPDATE,
        value
    })
}


export function cleanNotas() {
    dispatch({
        type: TYPES.CLEAN_NOTAS
    })
}

export function setBlock(value) {
    dispatch({
        type: TYPES.SET_BLOCK,
        value
    })
}

export function setItemsSelected(value) {
    dispatch({
        type: TYPES.SET_ITEMS_SELECTED,
        value
    })
}