import React from 'react'
import logo from '../../assets/images/logo.png'

function Navbar() {
    return (
        <nav className="navbar navbar-light bg-light">
            <div className="container-fluid">
                <img src={logo} alt="Logo Cassol"/>
            </div>
        </nav>
    );
}

export default Navbar
