import Swal from 'sweetalert2'
import * as action from '../../store/modules/notas/actions'

export const SimpleAlert = (icon, title, message, onConfirmed) => {
    Swal.fire({
        icon: icon,
        title: title,
        text: message,
        didDestroy: () => {
            if (onConfirmed) {
                onConfirmed()
            }
        }
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirmed()
        }
    })
}

export const Toast = (icon, message, postion, timer, timerProgressBar) => {

    let toast = Swal.mixin({
        toast: true,
        position: postion,
        showConfirmButton: false,
        timer: timer,
        timerProgressBar: timerProgressBar,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    toast.fire({
        icon: icon,
        title: message
    })

}

export const ConfirmedAlert = (Title, message, icon, onConfirmed, onCancel) => {


    Swal.fire({
        title: Title,
        text: message,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        
    }).then((result) => {
        if (result.isConfirmed) {
            BlockAlert('Processando !', 'Aguarde')
            onConfirmed()
        } else {
            if (onCancel) {
                onCancel()
            }
        }
    })

}

export const BlockAlert = (Title, message) => {
    Swal.fire({
        title: Title,
        text: message,
        timer: 60000,
        backdrop: false,
        didOpen: () => {
            action.setBlock(true)
            Swal.showLoading()
        },
        didDestroy: () => {
            action.setBlock(false)
        }
    })
}

export const SubmitAlert = (Title) => {

    Swal.fire({
        title: Title,
        input: 'text',
        inputAttributes: {
            autocapitalize: 'off'
        },
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
        
    }).then((result) => {
        if (result.isConfirmed) {
            localStorage.setItem('urlApi',result.value)
        }
    })

}