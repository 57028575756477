const STORE_ID = "NOTA";

const TYPES = {
    NOTA: "NOTA",
    NOTA_COPY: "NOTA_COPY",
    INVALID_NOTA: "INVALID_NOTA",
    NOTAS: "NOTAS",
    CLEAN_INVALID_NOTA: "CLEAN_INVALID_NOTA",
    CLEAN: "CLEAN",
    CLEAN_NOTAS: "CLEAN_NOTAS",
    PESQUISA: "PESQUISA",
    UPDATE: "UPDATE",
    SET_BLOCK: 'SET_BLOCK',
    SET_ITEMS_SELECTED: 'SET_ITEMS_SELECTED'
    
}

export { STORE_ID, TYPES }