import React from 'react'


import Typography from '@material-ui/core/Typography';

import { Fade, Tooltip, withStyles } from "@material-ui/core";


export const ToolTip = (props) => {

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 355,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);

    return (
        <HtmlTooltip
            placement="top-start"
            TransitionComponent={Fade}
            title={
                <React.Fragment>
                    <Typography color="inherit">{props.title}</Typography>
                </React.Fragment>
            }
        >
            <label>{props.title}</label>
        </HtmlTooltip>
    )
}