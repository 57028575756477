import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
    },
    pesquisa: {
        filial: '',
        dataInicial: '',
        dataFinal: '',
        tipoNota: "E",
        statusNota: null,
        chaveNota: '',
        numeroNota: ''
    },
    copy: {},
    invalid: {
        descricao: false,
    },
    items: [],
    observacao: "",
    block: false,
    itemsSelected: []
}

export default function notas(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.NOTAS:
                    return {
                        ...state,
                        items: action.value
                    }
                case TYPES.NOTA:
                    return {
                        ...state,
                        item: action.value
                    }
                case TYPES.INVALID_NOTA:
                    return {
                        ...state,
                        invalid: {
                            ...state.invalid,
                            [action.field]: true
                        }
                    }
                case TYPES.CLEAN_INVALID_NOTA:
                    return {
                        ...state,
                        invalid: {
                            ...state.invalid,
                            [action.field]: false
                        }
                    }
                case TYPES.NOTA_COPY:
                    return {
                        ...state,
                        copy: action.value
                    }
                case TYPES.CLEAN:
                    return {
                        ...state,
                        pesquisa: INITIAL_STATE.pesquisa,
                        items: []
                    }
                case TYPES.PESQUISA:
                    return { ...state, pesquisa: { ...state.pesquisa, [action.field]: action.value } }

                case TYPES.UPDATE:
                    return { ...state, observacao: action.value }

                case TYPES.SET_BLOCK:
                    return { ...state, block: action.value }

                case TYPES.SET_ITEMS_SELECTED:
                    return {
                        ...state, itemsSelected: action.value
                    }
                case TYPES.CLEAN_NOTAS:
                    return { ...state, items: INITIAL_STATE.items }

                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}