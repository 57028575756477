import React, { createRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoopIcon from '@material-ui/icons/Loop';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Label,
  Row
} from 'reactstrap';

import Navbar from "./components/Navbar/Navbar";

import ReactTable from "react-table-6";

import NotificationAlert from "react-notification-alert";
import * as notasActions from "./store/modules/notas/actions";
import * as controllerNotas from "./controllers/controllerNotas";
import { version } from '../package.json'

import './App.css';
import 'react-table-6/react-table.css';
import { numberString } from "./util/mask";
import { SimpleAlert, BlockAlert, ConfirmedAlert } from './components/Alert/Alert'
import { ToolTip } from './components/ToolTip/ToolTip'

function App() {

  // useEffect(() => {
  //   // if (localStorage.getItem('urlApi') !== '') {
  //   //   SubmitAlert('Coloque a url da api')
  //   // }
  //   console.log(process.env.CASSOL_API)
  // }, [])

  const [state, setState] = useState({
    selectionChanged: false,
    rowHighSelect: [],
    rowEdit: null,
    open: false,
    addvalue: "",
    value: "",
    editableRows: {},
    selectedRowIndex: [],
    showEdit: false,
    showAdd: false,
    showDelete: false,
    selected: null
  })
  const [checkedAll, setCheckedAll] = useState(false)
  const notification = createRef();
  const { items, pesquisa, block } = useSelector(state => state.notas)

  useEffect(() => {

    if (items.length === 0) {
      setCheckedAll(false)
    }
  
  }, [items])

  function pesquisar() {
    notasActions.cleanNotas()
    controllerNotas.get(pesquisa)

  }

  function onChangePesquisa(value, campo) {
    notasActions.pesquisa(value, campo)
  }

  function clean() {
    notasActions.clean()
  }

  async function controllerPut(props, params) {

    let ret = await controllerNotas.put(props.original, pesquisa.tipoNota, params)
    if (ret.status) {
      SimpleAlert('success', '', ret.data.retorno, () => pesquisar())
    }

  }

  const onPut = (props, params) => {
    if (params) {
      ConfirmedAlert('Atenção', 'Deseja reprocessar a nota', 'question', () => controllerPut(props, params), false)
    }
  }
  const onSearch = () => {
    pesquisar()
    BlockAlert('Processando !', 'Aguarde')
  }

  const returnOpcoes = (props) => {

    let vStatusErro = props.original.status === 0
    let vStatus = props.original.status === 4 || props.original.status === 2

    return (
      <div style={{ textAlign: "center" }}>
        { vStatusErro ?
          <LoopIcon
            style={{ cursor: "pointer", marginRight: '15px' }}
            onClick={() => onPut(props, true)}
          />
          :
          null
        }
        { vStatus ?
          <>
            <LoopIcon
              style={{ cursor: "pointer", marginRight: '15px' }}
              onClick={() => { onPut(props, true); }}
            />
            <GavelOutlinedIcon
              style={{ cursor: "pointer", marginRight: '15px' }}
              onClick={() => onPut(props, false)}
            />
          </>
          :
          null
        }
      </div>
    )
  }
  function checkNota(nota) {
    items.forEach(data => {
      if (data.numeroNota === nota.numeroNota) {
        data.checked = !data.checked
      }
    });
  }
  function checkAll() {
    setCheckedAll(!checkedAll)
    items.forEach(data => {
      data.checked = !checkedAll
    });
  }

  async function controllerSelectedPut(item, params) {
    let ret = await controllerNotas.put(item, pesquisa.tipoNota, params)
    if (ret && ret.status) {
      return true;
    }
    return false;
  }

  async function sendAllSelected(itemsSelected) {

    let valid
    if (itemsSelected.length !== 0) {

      itemsSelected.forEach(async (item, index) => {

        let ret = await controllerSelectedPut(item, false)

        if (ret) {
          valid = ret;
        }

        if (index === itemsSelected.length - 1) {
          SimpleAlert('success', '', 'Notas enviadas com sucesso', () => pesquisar())
        }
      });

      return valid

    }

  }
  async function showMessageSend() {

    let valid = false

    let itemsSelected = items.filter((item => item.checked && item.status !== 3))

    ConfirmedAlert('Atenção', 'Deseja enviar de  a nota', 'question', async () => valid = await sendAllSelected(itemsSelected), false)

    return valid

  }

  async function sendSelected() {

    await showMessageSend()

  }

  return (
    <>
      <div className={block ? 'ver-cadastro' : ''}></div>
      <Navbar />
      <NotificationAlert ref={notification} />
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <Label style={{ margin: "10px 0" }}>Controle de Envio de NF-e</Label>
            </Col>
            <Col md={1}>
              <Label style={{ margin: "10px 0" }}>{version}</Label>
            </Col>
          </Row>
          <Row>
            <Row>
              <Col>
                <Label> Filial </Label>
                <Input
                  type="text"
                  placeholder="Ex: 001"
                  value={pesquisa.filial}
                  onChange={event => onChangePesquisa(numberString(event.target.value), 'filial')}
                />
              </Col>
              <Col>
                <Label>
                  Inicio
              </Label>
                <Input
                  type="date"
                  value={pesquisa.dataInicial}
                  onChange={e => onChangePesquisa(e.target.value, 'dataInicial')}
                />
              </Col>
              <Col>
                <Label>
                  Final
              </Label>
                <Input
                  type="date"
                  value={pesquisa.dataFinal}
                  onChange={e => onChangePesquisa(e.target.value, 'dataFinal')}
                />
              </Col>
              <Col>
                <Label>
                  Tipo de Nota
              </Label>
                <Input

                  type="select"
                  onChange={e => onChangePesquisa(e.target.value, 'tipoNota')}
                  onLoad={e => onChangePesquisa(e.target.value, 'tipoNota')}
                >
                  <option value="E">Entrada</option>
                  <option value="S">Saída</option>
                </Input>
              </Col>
              <Col>
                <Label>
                  Status
              </Label>
                <Input
                  type="select"
                  onChange={e => onChangePesquisa(e.target.value, 'statusNota')}
                  onLoad={e => onChangePesquisa(e.target.value, 'statusNota')}
                  value={pesquisa.statusNota}
                >
                  <option value={null}>Todos</option>
                  <option value={2}>Enviado</option>
                  <option value={3}>Autorizado</option>
                  <option value={4}>Erro</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label> Chave NF-e </Label>
                <Input
                  type="text"
                  value={pesquisa.chaveNota}
                  placeholder="Informe a Chave da Nota"
                  onChange={e => onChangePesquisa(numberString(e.target.value), 'chaveNota')}
                />
              </Col>

              <Col>
                <Label> Número da Nota Fiscal </Label>
                <Input
                  type="text"
                  placeholder="Informe o Número da Nota Fiscal"
                  value={pesquisa.numeroNota}
                  onChange={e => onChangePesquisa(numberString(e.target.value), 'numeroNota')}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonGroup>
                  <Button
                    className="buttons-pesquisa"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      onSearch()
                    }}
                  >
                    Pesquisar
              </Button>
                  <Button
                    className="buttons-pesquisa"
                    style={{ margin: "10px" }}
                    onClick={clean}
                  >
                    Limpar
              </Button>
                </ButtonGroup>

              </Col>
              <Col md='2'>
                <ButtonGroup style={{ display: 'flex', justifyContent: 'right' }} >
                  <Button
                    className="buttons-pesquisa"
                    style={{ margin: "10px" }}
                    onClick={sendSelected}
                  >
                    Enviar Selecionadas
                    <LoopIcon style={{ marginLeft: '1rem' }} />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Row>
        </CardHeader>
        {!(items.length === 0) &&
          <>
            <CardBody>

              <ReactTable
                data={items}
                filterable={false}
                NoDataComponent={() => (<></>)}
                isSelected={true}
                isSomeSelected={true}
                columns={
                  [
                    {
                      id: "nota",
                      accessor: "",
                      Header: () => {
                        return (
                          <div style={{ display: "flex", justifyContent: 'center' }}>
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={checkedAll}
                              onChange={() => checkAll()}
                            />
                          </div>
                        );
                      },
                      Cell: ({ row }) => {
                        return (
                          <div style={{ display: "flex", justifyContent: 'center' }}>
                            <input
                              style={{ textAlign: "center" }}
                              type="checkbox"
                              className="checkbox"
                              value={row.nota.checked}
                              checked={row.nota.checked}
                              onChange={() => { checkNota(row) }}
                            />
                          </div>
                        );
                      },
                      width: 44
                    },

                    {
                      Header: "NOTA",
                      accessor: "numeroNota",
                      width: 60
                    },
                    {
                      Header: "DATA",
                      accessor: "dataNota",
                      width: 100,
                      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                    },
                    {
                      Header: "DATA PROCESSAMENTO",
                      accessor: "dataProcessamento",
                      width: 150,
                      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                    },
                    {
                      Header: "EMPRESA",
                      accessor: "filialNota",
                      width: 65
                    },
                    {
                      Header: "CLIENTE",
                      accessor: "clienteNota",
                      width: 250
                    },
                    {
                      Header: "VALOR",
                      accessor: "valorNota",
                      width: 100,
                      Cell: row => <div style={{ textAlign: "right" }}>{row.value.toFixed(2)}</div>
                    },
                    {
                      Header: "SÉRIE",
                      accessor: "serieNota",
                      width: 50,
                      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                    },
                    {
                      Header: "OBSERVAÇÃO",
                      accessor: "retornoNota",
                      width: 260,
                      Cell: row => (
                        <ToolTip title={row.value} />
                      )
                    },
                    {
                      Header: "OPÇÕES",
                      Cell: (props) => returnOpcoes(props)
                    }
                  ]
                }
                useRowSelect={true}
                minRows={1}
                defaultPageSize={10}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                getTrProps={(_, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: e => {

                        if (rowInfo.index !== state.rowEdit) {
                          setState({
                            rowEdit: rowInfo.index,
                            selectedRowIndex: rowInfo.original,
                            selectionChanged: state.selectionChanged
                              ? false
                              : true
                          });
                        } else {
                          setState({
                            rowEdit: null
                          });
                        }
                      },
                      style: {
                        background:
                          rowInfo.index === state.rowEdit || rowInfo.row.nota.checked ? "#00afec" : "white",
                        color:
                          rowInfo.index === state.rowEdit || rowInfo.row.nota.checked ? "white" : "black"
                      }
                    };
                  } else {
                    return {};
                  }
                }}
              />
            </CardBody>
            <CardFooter>
              <div className="float-right">
                <p className="fw-bolder text-uppercase">Quantidade: {items.length}</p>
              </div>
            </CardFooter>
          </>
        }
      </Card>
    </>
  );
}

export default App;