
import { Settings } from './appSettings.js'

var api

if (Settings.ambient === 'development') {
    api = { urlNota: 'http://localhost:8080/api/' }

} else if (Settings.ambient === 'production') {
    api = { urlNota: 'http://nfeapi.cassol.local/api/' }

} else if (Settings.ambient === 'homolog') {
    api = { urlNota: 'nfeapphml0001.cassol.local/api/' }

}

export default api;
